.home-container {
  display: flex;
}
.inner-dos {
  background-color: #fce7e7a6;
}
.inner-buro {
  background-color: #eceefda6;
}
.user-view-inner {
  width: 50%;
  margin: 10px;
  box-shadow: 0 0 2px 2px gray;
  padding: 10px;
}
.text-wrapper {
  color: gray;
}

.user-info-wrapper {
  margin-top: 10px;
  height: 20px;
  display: flex;
  align-items: center;
}
.user-info-button-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}
.user-info-button-tools-wrapper {
  margin-top: 40px;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 2px 2px;
}
.user-info-date-picker {
  margin-left: 5px;
  padding: 3px;
  font-size: 14px;
}

.button-close-bot {
  margin-top: 40px;
  padding: 20px;
  box-shadow: 0 0 2px 2px;
}

.payment-info-container {
  position: relative;
  margin-top: 40px;
  padding: 20px;
  box-shadow: 0 0 2px 2px;
  height: 250px;
  overflow-y: scroll;
}
.payment-info-tools-container {
  position: absolute;
  top: 20px;
  right: 20px;
}


.query-info-container {
  position: relative;
  margin-top: 40px;
  padding: 20px;
  box-shadow: 0 0 2px 2px;
}
.query-info-tools-container {
  position: absolute;
  top: 20px;
  right: 20px;
}
.query-info-text {
  margin-top: 10px;
}


@media (max-width: 900px) {
  .home-container {
    flex-direction: column;
  }
  .user-view-inner {
    width: 100%;
    margin: 10px 0;
    padding: 20px;
  }
}


@media (max-width: 450px) {
  .user-info-button-tools-wrapper {
    flex-direction: column;

  }
  .user-info-button-wrapper {
    flex-direction: column;
  }
  .user-info-wrapper {
    flex-direction: column;
    border: 1px solid black;
    padding: 5px;
    align-items: end;
    height: auto;
    width: 100%;
  }
  .text-wrapper {
    margin-bottom: 10px;
  }

  .query-info-container {
    padding-top: 40px;
  }
  .payment-info-container {
    padding-top: 40px;
    height: 400px;
  }
}
