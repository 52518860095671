* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
html,
body {
	height: 100%;
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}
main {
	flex: 1 0 auto;
}

.title {
	text-align: center;
  margin: 10px;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}
.title3 {
	font-size: 1.17rem;
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 15px;
}
.center-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.center-inner {
	box-shadow: 0 0 2px 2px gray;
  padding: 20px;
	width: 350px;
}
